import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Table,
  Row,
  Col,
  Select,
  Button,
  Tooltip,
  DatePicker,
  Space,
  TimeRangePickerProps,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';
import { getLogListReq } from '../../../actions/logs';
import { InputSearch } from '../../common/doubleinput/InputSearch';
import { getOrderFromTableParams } from '../../../hooks/utils';
import { InputPageTitle } from '../../common/doubleinput/InputPageTitle';

import ResetSort from '../../../img/resetSort.svg';
import ResetFilter from '../../../img/resetFilter.svg';
import { carrierData } from './constant';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import {
  parseTimeStringFormat,
  parseDateTimeStringFormat,
  parseDateWitoutTimeStringFormat,
  getEventLabel,
} from '../driver_log/log-utils';
import { ModalGoogleMapTracker } from '../../common/GoogleModal';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
import TableSpinner from '../../spiner/TableSpinner';

const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);

const timeFormat = (second: number) => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.floor((second % 3600) / 60);
  const seconds = second % 60;
  const addLeadingZero = (value: number): string => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const formattedTime = {
    hours: addLeadingZero(hours),
    minutes: addLeadingZero(minutes),
    seconds: addLeadingZero(seconds),
  };
  return formattedTime;
};

export const LogList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleTableChange,
    tableParams,
    rowSelection,
    clearOrderFilters,
    setSearchParam,
    hasFiltersOrOrder,
    clearFilter,
    clearOrder,
    clearCustomFilter,
    setCustomFilter,
  } = useTableParams({});
  const logs = useSelector((state: any) => state.log.logList);
  const carriers = useSelector((state: any) => state.carrier.carrierList);

  const count = useSelector((state: any) => state.log.count);
  const loading = useSelector((state: any) => state.log.loading);
  const [logDateFilter, setLogDateFilter] = React.useState<any>('');

  React.useEffect(() => {
    dispatch(
      getLogListReq({
        queryParams: {
          with: [
            'drivers',
            'driver',
            'vehicles',
            'carrier',
            'vehicle',
            'carriers',
            'unit',
          ],
        },
      }),
    );
  }, []);
  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last 8 days',
      value: [dayjs().subtract(8, 'd').startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last Month',
      value: [dayjs().subtract(1, 'M').startOf('day'), dayjs().endOf('day')],
    },
  ];

  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: (name, record, index) => {
        return <div>{dayjs(record.datetime).format('MM/DD/YYYY')}</div>;
      },
      width: '10%',
      ellipsis: true,
    },
    {
      title: 'Driver',
      dataIndex: 'driver',
      key: 'ndriverame',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu"
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            {record?.driver?.first_name} {record?.driver?.last_name}
          </div>
        );
      },
      ellipsis: true,
      width: '10%',
      filters: [
        { key: 1, value: 'David Andresson' },
        { key: 2, value: 'John Smith' },
      ].map((st: any) => {
        return {
          text: st.value,
          value: st.key,
        };
      }),
      filteredValue: tableParams?.filters?.driver || null,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      width: '9%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.unit?.vehicle?.identificator}
          </div>
        );
      },
      filters: [
        { key: 1, value: 'Vehicle 1' },
        { key: 2, value: 'Vehicle 2' },
      ].map((st: any) => {
        return {
          text: st.value,
          value: st.key,
        };
      }),
      filteredValue: tableParams?.filters?.group || null,
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div
            className="ubuntu"
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            // onClick={() => {
            //   navigate(`/client/carriers/${record?.carrier?.id}`);
            // }}
          >
            <div>{record?.carrier?.name}</div>
          </div>
        );
      },
    },
    {
      title: 'Last Status',
      dataIndex: 'status',
      key: 'status',
      render: (name, record, index) => {
        const lastStatus = getEventLabel(record.event_type, record.event_code);
        const time = timeFormat(record.duration);
        return (
          <div>
            {lastStatus} : {time.hours}:{time.minutes}
          </div>
        );
      },
      ellipsis: true,
      width: '12%',
      filters: [
        { key: 1, value: 'SB' },
        { key: 2, value: 'ON' },
        { key: 3, value: 'PC' },
        { key: 4, value: 'OFF' },
        { key: 5, value: 'D' },
        { key: 6, value: 'YM' },
      ].map((st: any) => {
        return {
          text: st.value,
          value: st.key,
        };
      }),
      filteredValue: tableParams?.filters?.group || null,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.location}
          </div>
        );
      },
    },
    {
      title: 'D',
      dataIndex: 'ndriving',
      key: 'driving',
      width: '5%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {`4:15`}
          </div>
        );
      },
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
      width: '7%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {`2:25`}
          </div>
        );
      },
    },
    {
      title: 'Cycle',
      dataIndex: 'cycle',
      key: 'cycle',
      width: '7%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {`00:17`}
          </div>
        );
      },
    },
    {
      title: 'Worked',
      dataIndex: 'tbd',
      key: 'tbd',
      render: (name, record, index) => {
        return <div>{`05:37`}</div>;
      },
      ellipsis: true,
      width: '7%',
    },
    {
      title: 'Violations',
      dataIndex: 'violations',
      key: 'violations',
      render: (value, record, index) => {
        return <div>No</div>;
      },
      width: '10%',
      ellipsis: true,
      filters: [
        { value: 'No', key: 'No' },
        { value: 'Form & Manner', key: 2 },
        { value: 'HOS', key: 3 },
      ].map((st: any) => {
        return {
          text: st.value,
          value: st.key,
        };
      }),
      filteredValue: tableParams?.filters?.status || null,
    },
    {
      title: 'DOT',
      dataIndex: 'dot',
      key: 'dot',
      width: '7%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div>
            <Tooltip title="DOT Location: NY">
              <span>No</span>
            </Tooltip>
          </div>
        );
      },
      filters: [
        { key: 'Yes', value: 1 },
        { key: 'No', value: 1 },
      ].map((st: any) => {
        return {
          text: st.key,
          value: st.value,
        };
      }),
      filteredValue: tableParams?.filters?.status || null,
    },
  ];
  const onRowClick = (record: any) => {
    const end = new Date();
    const parsedEndDate = end.toISOString().split('T')[0];
    const start = new Date();
    start.setDate(start.getDate() - 8);
    const parsedStartDate = start.toISOString().split('T')[0];
    window.open(
      `/logbook/${record.driver.id}/${parsedStartDate}/${parsedEndDate}`,
      '_blank',
    );
  };
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          <MainContainer>
            <Row
              style={{
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MainHeader titles={[{ text: 'Logs' }]} />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 15,
                  paddingRight: 10,
                }}
              >
                <div style={{ display: 'flex', width: 250 }}>
                  <RangePicker
                    value={tableParams.filters?.period}
                    onChange={(v, d) => {
                      setLogDateFilter(v);
                      setCustomFilter('period', v);
                    }}
                    presets={rangePresets}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    placeholder="Select carrier"
                    mode="multiple"
                    maxTagCount="responsive"
                    style={{ width: 260 }}
                    value={tableParams.filters?.carrier}
                    onChange={(value) => {
                      clearCustomFilter('group');
                      setCustomFilter('carrier', value);
                    }}
                  >
                    {carriers?.map((carrier: any) => {
                      return (
                        <Select.Option key={carrier.id} value={carrier.id}>
                          {carrier.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <InputSearch
                  onChange={setSearchParam}
                  onClear={clearOrderFilters}
                  hasFilters={hasFiltersOrOrder}
                />
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={clearFilter}
                  >
                    <div style={{ marginRight: 5 }}>
                      <img src={ResetFilter} />
                    </div>
                    <div
                      className="ubuntu"
                      style={{ color: '#8A8996', fontSize: 12 }}
                    >
                      Reset filter
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <div style={{ width: '100%' }} className="logs-table">
              <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={logs}
                pagination={{
                  ...tableParams.pagination,
                  position: ['bottomCenter'],
                  total: defaultTo(logs?.length, count),
                }}
                loading={{
                  spinning: loading,
                  indicator: <TableSpinner />,
                }}
                onChange={handleTableChange}
                rowSelection={{ ...rowSelection, columnWidth: 10 }}
                className="table-custom"
                onRow={(record) => ({
                  onClick: () => onRowClick(record),
                })}
                rowClassName="pointer-row"
              />
            </div>
          </MainContainer>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
