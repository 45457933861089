import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCarriersListReq } from '../../../actions/carrier';

import { Form, Button, Modal } from 'antd';
import { CommonInput } from '../../common/inputs';
import { createLogForm } from './log-form';
import { InputType } from '../../../constants/inputs';
import {
  createDriverLogReq,
  getDriverLogListReq,
} from '../../../actions/driver_log';
import { usePermissions } from '../../../hooks/usePermissions';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { getLogForm } from '../../../actions';
import { getTzValue } from '../../../utils/utils';
import { annotations } from './fields/log-contant';
dayjs.extend(buddhistEra);

export const CreateDriverLogModal = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const { currentCarrier } = useSelector((state: any) => state.vehicle);
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const driverConfigLog = useSelector(
    (state: any) => state?.log?.driverConfigLog?.data,
  );
  const { user } = useSelector((state: any) => state.auth);
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentConfig, setCurrentCongif] = useState<any>({});
  const [unit, setUnit] = useState('');
  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  const [initialValues, setInitialValues] = useState({
    identificator: '',
    vehicle: currentConfig?.vehicle?.id || '',
    device: currentConfig?.device?.identificator || '',
    codriver: null,
    trailer: null,
    event_type: null,
    event_code: null,
    status: null,
    notes: null,
    timestamp: driverLogDate,
    time: null,
    shipping_doc: null,
    record_origin: null,
    record_status: 1,
    total_miles: null,
    total_hours: null,
    latitude: null,
    longitude: null,
  });
  const handleCancel = () => {
    setIsModalOpen(false);
    form.setFieldsValue({
      ...initialValues,
    });
    form.setFieldValue('event', '');
    form.setFieldValue('annotations', '');
  };
  React.useEffect(() => {
    form.setFieldValue('timestamp', driverLogDate);
  }, [driverLogDate, isModalOpen]);

  React.useEffect(() => {
    if (isModalOpen) {
      dispatch(
        getCarriersListReq({
          queryParams: {
            with: ['settings', 'terminals', 'driver_groups'],
          },
        }),
      );
    }
  }, [isModalOpen]);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
    });
  }, [currentCarrier]);

  const handleSubmit = async (values: any) => {
    const hours = values.time.slice(0, 2);
    const minutes = values.time.slice(3, 5);
    const seconds = values.time.slice(6, 8);

    annotations;

    const data = {
      driver: params.driverid,
      event_type: +values?.event[0],
      event_code: +values?.event[1],
      record_origin: values?.record_origin,
      record_status: 1,
      vehicle: values?.vehicle,
      total_miles: values?.total_miles,
      total_hours: values?.total_hours,
      latitude: values?.latitude,
      longitude: values?.longitude,
      codriver: values?.codriver,
      shipping_doc:
        values?.shipping_doc?.length != 0 ? values?.shipping_doc : null,
      date: dayjs(values.timestamp).format('YYYY-MM-DD'),
      time: `${hours}:${minutes}:${seconds}`,
      location: values?.location,
      note: values?.annotations,
      device: values?.device ? values?.device : unit,
      trailer: null,
    };

    dispatch(
      createDriverLogReq({
        values: data,
        onSuccess: () => {
          dispatch(
            getDriverLogListReq({
              queryParams: {
                with: [
                  'driver_groups',
                  'vehicles',
                  'drivers',
                  'vehicle',
                  'driver',
                  'codriver',
                ],
              },
              driverid: params?.driverid,
              date: driverLogDate,
            }),
          );
          handleCancel();
        },
      }),
    );
  };

  const [hourError, setHourError] = useState(0);

  const getCreateForm = () => {
    const time = form.getFieldValue('time');
    const day = dayjs(form.getFieldValue('timestamp')).tz(tz).startOf('day');
    const hours = time.slice(0, 2);
    const minutes = time.slice(3, 5);
    const seconds = time.slice(6, 8);

    const timestamp = day
      .add(hours, 'hour')
      .add(minutes, 'minute')
      .add(seconds, 'second')
      .add(1, 'day')
      .valueOf();
    dispatch(
      getLogForm({
        queryParams: {
          id: params.driverid,
          timestamp: timestamp / 1000,
          with: ['driver', 'vehicle', 'carrier', 'unit'],
        },
      }),
    );
    setTimeout(() => {
      if (driverConfigLog) {
        const data = {
          vehicle: driverConfigLog?.vehicle?.id,
          codriver: driverConfigLog?.codriver?.id,
          shipping_doc: driverConfigLog?.shipping_doc,
          trailer: driverConfigLog?.trailer,
          device: driverConfigLog?.unit?.device?.id,
          unit: driverConfigLog?.unit?.id,
        };
        setUnit(driverConfigLog?.unit?.device?.id);
        form.setFieldsValue(data);
      }
    }, 200);
  };
  const changeValue = (e: any) => {
    if (e.nativeEvent.target.id === 'time') {
      let value: string = form.getFieldValue('time');
      if (value) {
        value = value.replace(/\D/g, '');
        const hours = parseInt(value.slice(0, 2), 10);
        if (hours < 0 || hours > 24) {
          setHourError(1);
          value = value.slice(0, 2);
        } else {
          setHourError(0);
        }
        const minutes = parseInt(value.slice(2, 4), 10);
        if (minutes < 0 || minutes > 59) {
          setHourError(1);
          value = value.slice(0, 4);
        } else {
          setHourError(0);
        }
        const seconds = parseInt(value.slice(4, 6), 10);
        if (seconds < 0 || seconds > 59) {
          setHourError(1);
          value = value.slice(0, 6);
        } else {
          setHourError(0);
        }
        value = value.slice(0, 6);
        if (value.length === 6) {
          getCreateForm();
        }
        value = value.replace(/(..)(?!$)/g, '$1:');
        form.setFieldValue('time', value);
      }
    }
  };

  const datePickerChange = (value: any) => {
    form.setFieldValue('time', '');
    form.setFieldValue('vehicle', '');
    form.setFieldValue('codriver', '');
    form.setFieldValue('shipping_doc', '');
    form.setFieldValue('trailer', '');
    form.setFieldValue('device', '');
    form.setFieldValue('note', '');
    form.setFieldValue('unit', '');

    setHourError(0);
  };

  return (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={showModal}
      >
        <div
          className="orange"
          style={{
            fontSize: 12,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 10,
            background: '#f5f9ff',
            padding: '10px',
            width: 'fit-content',
            gap: 5,
          }}
        >
          <span
            className="icon-fi-rr-plus orange"
            style={{ width: 'fit-content' }}
          />
          Create
        </div>
      </div>

      <Modal
        title="Create Event"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues}
          onChange={changeValue}
        >
          {createLogForm(datePickerChange).map((field: any, i: number) => {
            if (field.type === InputType.ADD_DYNAMIC) {
              return (
                <CommonInput
                  currentIndex={currentIndex}
                  fields={fields}
                  key={i}
                  setCurrentIndex={setCurrentIndex}
                  {...field}
                  form={form}
                />
              );
            }
            return (
              <CommonInput
                key={i}
                {...field}
                errorVisible={hourError}
                form={form}
              />
            );
          })}
          <Form.Item style={{ width: '100%', display: 'flex' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="orange"
              style={{ width: '65px', marginRight: 12 }}
            >
              Save
            </Button>
            <Button
              className="grey"
              style={{ width: '85px', marginRight: 12 }}
              onClick={() => {
                // form.setFieldsValue(initialValues);
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
